import { animate, style, transition, trigger } from '@angular/animations';

export const toastAnimation = trigger('toastAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(100%)' }),
    animate(`200ms ease-out`, style({ opacity: 1, transform: 'translateY(0%)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0%)' }),
    animate(`200ms ease-out`, style({ opacity: 0, transform: 'translateY(100%)' })),
  ]),
]);
