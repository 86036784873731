<div class="modal-body">
  <img appAssetSrc="assets/svg/network/user-invited.svg" />
  <h1>{{ 'contactSelector.newUserInvited' | translate }}</h1>
  <div>{{ 'contactSelector.newUserInvitedMsg' | translate }}</div>
  <button
    (click)="activeModal.close()"
    class="btn btn-primary submit-btn"
  >
    {{ 'common.buttons.ok' | translate }}
  </button>
</div>
