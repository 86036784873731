import { Label } from '@conversations/workspaces/state/labels/labels-state.model';

export class LoadLabels {
  static readonly type = '[Labels] Load Labels';

  constructor(public workspaceIds: number[]) {}
}

export class LoadLabel {
  static readonly type = '[Labels] Load Label';

  constructor(
    public workspaceId: number,
    public labelId: number,
  ) {}
}

export class CreateLabel {
  static readonly type = '[Labels] Create Label';

  constructor(
    public workspaceId: number,
    public label: Pick<Label, 'name' | 'backgroundColor' | 'foregroundColor' | 'visibleInNavigation'>,
  ) {}
}

export class UpdateLabel {
  static readonly type = '[Labels] Update Label';

  constructor(
    public workspaceId: number,
    public labelId: number,
    public newValues: Partial<Pick<Label, 'name' | 'backgroundColor' | 'foregroundColor' | 'visibleInNavigation'>>,
  ) {}
}

export class DeleteLabel {
  static readonly type = '[Labels] Delete Label';

  constructor(
    public workspaceId: number,
    public labelId: number,
  ) {}
}
