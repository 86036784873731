import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { map, type Observable } from 'rxjs';

import {
  getOffsetPagingOptionsParams,
  type OffsetPagingOptions,
  type OffsetPagingWrapper,
} from '@clover/core/helpers/paging';
import { HttpService } from '@clover/core/services/http.service';

import type { TaskCommunicationRole, TaskPerformer } from '../../tasks.model';
import {
  mapTaskPerformer,
  mapTaskRole,
  type TaskCommunicationRoleResponse,
  type TaskPerformerResponse,
} from '../../tasks.service';

interface TaskActivityEntryResponse {
  id: number;
  type: TaskHistoryEventType;
  assignedTo?: {
    communicationRoles: TaskCommunicationRoleResponse[];
    users: TaskPerformerResponse[];
  };
  performer?: TaskPerformerResponse;
  stepName?: string;
  sequenceStepNumber?: number;
  totalStepNumber?: number;
  copiedFromProduct?: {
    name: string;
  };
  createdAt: string;
}

export interface TaskActivityEntry {
  id: number;
  type: TaskHistoryEventType;
  createdAt: string;
  metadata: {
    stepName?: string;
    stepIndex?: number;
    stepsCount?: number;
    assigneeRoles?: TaskCommunicationRole[];
    assigneeUsers?: TaskPerformer[];
    performer?: TaskPerformer;
    copiedFromProductName?: string;
  };
}

export enum TaskHistoryEventType {
  TaskStarted = 'TaskStarted',
  Assigned = 'TaskAssigned',
  Reassigned = 'TaskReassigned',
  TaskAccepted = 'TaskAccepted',
  StepStarted = 'StepStarted',
  StepSubmitted = 'StepSubmitted',
  StepRestarted = 'StepRestarted',
  TaskClosed = 'TaskClosed',
  TaskCompleted = 'TaskCompleted',
  ProductTaskCopied = 'ProductTaskCopied',
}

function mapTaskActivityEntry(e: TaskActivityEntryResponse): TaskActivityEntry {
  return {
    id: e.id,
    type: e.type,
    createdAt: e.createdAt,
    metadata: {
      stepName: e.stepName,
      stepIndex: e.sequenceStepNumber,
      stepsCount: e.totalStepNumber,
      assigneeRoles: e.assignedTo?.communicationRoles?.map(mapTaskRole),
      assigneeUsers: e.assignedTo?.users?.map(mapTaskPerformer),
      performer: e.performer ? mapTaskPerformer(e.performer) : undefined,
      copiedFromProductName: e.copiedFromProduct?.name,
    },
  };
}

@Injectable({
  providedIn: 'root',
})
export class TaskActivityService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  searchTaskActivity(
    taskId: number,
    pagingOptions?: OffsetPagingOptions<never>,
  ): Observable<OffsetPagingWrapper<TaskActivityEntry>> {
    const urlTree = this.router.createUrlTree(['api', 'tasks', taskId, 'events'], {
      queryParams: getOffsetPagingOptionsParams(pagingOptions),
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<OffsetPagingWrapper<TaskActivityEntryResponse>>(path).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(mapTaskActivityEntry),
      })),
    );
  }
}
