import * as R from 'ramda';
import { Subscription } from 'rxjs';

interface Invoke {
  args: unknown[];
  subscription: Subscription;
}

export function ccPreventDuplicateSubscriptions(behaviour: 'first-wins' | 'last-wins' = 'first-wins') {
  return function (targetClass: unknown, methodName: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    const invokes: Invoke[] = [];

    // Override the method
    descriptor.value = function (...args: unknown[]): Subscription {
      // Find invoke with same arguments
      const identicalInvokeIndex = invokes.findIndex((invoke) => R.equals(invoke.args, args));

      if (identicalInvokeIndex !== -1) {
        // Get subscription of an identical invoke
        const { subscription } = invokes[identicalInvokeIndex];

        switch (behaviour) {
          case 'first-wins':
            // If not closed, return the subscription to prevent duplicate request.
            if (!subscription.closed) return subscription;

            // If closed, remove the invoke.
            invokes.splice(identicalInvokeIndex, 1);
            break;
          case 'last-wins':
            // Unsubscribe the previous subscription.
            subscription.unsubscribe();

            // Remove the previous invoke.
            invokes.splice(identicalInvokeIndex, 1);
            break;
        }
      }

      // Call the original method and store the subscription.
      const subscription = originalMethod.apply(this, args);
      invokes.push({ args, subscription });

      return subscription;
    };

    return descriptor;
  };
}
