<div class="cc-toast-container">
  <div class="cc-toast-container__message">
    <i
      [class.icon-error-fill]="type === ToastType.Error"
      [class.icon-info-fill]="type === ToastType.Info"
      [class.icon-success-fill]="type === ToastType.Success"
      [class.icon-warning-fill]="type === ToastType.Warning"
    ></i>

    <div class="cc-toast-container__message-text">
      {{ message }}
    </div>
  </div>

  <div class="cc-toast-container__close-icon">
    <app-button
      (click)="close.emit()"
      [ccTooltip]="'common.buttons.close' | translate"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
      class="cc-toast-container__close-icon__button"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>
</div>
