import { Overlay, OverlayConfig, type OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { inject, Injectable, type TemplateRef, type ViewContainerRef } from '@angular/core';
import { take } from 'rxjs/operators';

import { ToastType } from '@design/overlays/toast/toast';
import { ToastComponent } from '@design/overlays/toast/toast.component';

export interface PortalParams {
  containerRef: ViewContainerRef;
  templateRef: TemplateRef<unknown>;
  overlayConfig?: OverlayConfig;
  hasBackdrop?: boolean;
  backdropClickHandler?: (overlayRef: OverlayRef) => void;
}

@Injectable({
  providedIn: 'root',
})
export class CdkPortalService {
  private activeToastOverlayRef: OverlayRef | null = null;

  private readonly overlay = inject(Overlay);

  openPortal(params: PortalParams): OverlayRef {
    const overlayRef: OverlayRef = this.overlay.create(params.overlayConfig || this.setupDefaultOverlayConfig(params));
    overlayRef.attach(new TemplatePortal(params.templateRef, params.containerRef));

    overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        if (params.backdropClickHandler) {
          params.backdropClickHandler(overlayRef);
        } else {
          overlayRef.detach();
          overlayRef.dispose();
        }
      });

    return overlayRef;
  }

  presentToast(message: string, type: ToastType = ToastType.Info, hideAfterMs = 5000): void {
    this.closeActiveToast();

    const overlayConfig: OverlayConfig = new OverlayConfig({
      hasBackdrop: false,
      panelClass: 'clover-toast-panel',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay.position().global().centerHorizontally().bottom('20px'),
    });

    this.activeToastOverlayRef = this.overlay.create(overlayConfig);
    const toastComponentPortal = new ComponentPortal<ToastComponent>(ToastComponent);
    const componentRef = this.activeToastOverlayRef.attach(toastComponentPortal);

    componentRef.instance.message = message;
    componentRef.instance.hideAfterMs = hideAfterMs;
    componentRef.instance.type = type;
    componentRef.instance.close.subscribe(() => {
      this.closeActiveToast();
    });
  }

  private closeActiveToast(): void {
    if (this.activeToastOverlayRef) {
      this.activeToastOverlayRef.detach();
    }
  }

  private setupDefaultOverlayConfig(params: PortalParams): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: params.hasBackdrop || false,
      backdropClass: 'clover-modal-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
  }
}
