<div class="modal-body">
  <h1>{{ 'departmentsSelector.selectDepartments' | translate }}</h1>

  <form [formGroup]="form">
    <div
      class="form-group"
      [ngClass]="{
        'has-error': isFormSubmitted && form.get('departments').invalid,
      }"
    >
      <label
        class="required"
        for="departments"
        >{{ 'campaignEdit.controls.communicationRoles.label' | translate }}</label
      >
      <app-select
        [maxHeight]="130"
        formControlName="departments"
        [multiselect]="true"
        [returnFullOption]="true"
        placeholder="campaignEdit.controls.communicationRoles.placeholder"
        [options]="departments"
      >
      </app-select>
      <form-error-messages
        [showErrors]="isFormSubmitted"
        [control]="form.get('departments')"
      >
      </form-error-messages>
    </div>
  </form>

  <div class="buttons-group">
    <button
      class="btn btn-primary decline-btn"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      [disabled]="isFormSubmitted && form.invalid"
      (click)="submitSelection()"
      class="btn btn-primary submit-btn"
    >
      {{ (isAssigningTask ? 'common.buttons.assignTask' : 'common.buttons.select') | translate }}
    </button>
  </div>
</div>
