import { NgClass } from '@angular/common';
import { Component, Input, type OnInit, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { type EnumerationModel } from '@core/models/enumerationModel';
import { EnumService } from '@core/services/enum.service';
import { ToastrService } from '@core/services/toastr.service';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { SelectComponent } from '../../../core/components/select/select.component';

@UntilDestroy()
@Component({
  selector: 'app-department-selector-modal',
  templateUrl: './department-selector-modal.component.html',
  styleUrls: ['./department-selector-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, SelectComponent, FormErrorMessagesComponent, TranslateModule],
})
export class DepartmentSelectorModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly enumService = inject(EnumService);
  private readonly toastr = inject(ToastrService);
  @Input() preSelectedDepartments: EnumerationModel[] = [];
  @Input() isAssigningTask: boolean;

  public isFormSubmitted: boolean;
  public form: UntypedFormGroup;

  public departments: EnumerationModel[] = [];

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      departments: new UntypedFormControl(this.preSelectedDepartments, [Validators.required]),
    });

    this.enumService
      .getCommunicationRoles()
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.departments = res;
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          this.activeModal.dismiss();
          return of(err);
        }),
      )
      .subscribe();
  }

  public submitSelection(): void {
    this.isFormSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    this.activeModal.close(this.form.value.departments);
  }
}
