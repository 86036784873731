import {
  ConversationPerformer,
  ConversationStatus,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import { EMPTY_PAGING, PagingWrapper } from '@core/helpers/paging';

export interface ConversationStateModel {
  details: ConversationDetails | undefined;
  messages: PagingWrapper<Message>;
  pendingMessages: PendingMessage[];
  loadingStatus: 'void' | 'loading' | 'loaded' | 'error';
  messagesLoadingStatus: 'void' | 'loading' | 'loaded' | 'loading-prev' | 'loading-next' | 'error';
}

export const defaultConversationState: ConversationStateModel = {
  details: undefined,
  messages: EMPTY_PAGING,
  pendingMessages: [],
  loadingStatus: 'void',
  messagesLoadingStatus: 'void',
};

export interface ConversationDetails {
  id: string;
  subject: string;
  workspaceId: number;
  externalAccountId: number;
  labelIds: number[];
  lastDraft: LinkedEmailDraft | undefined;
  lastMessage: CompactMessage;
  lastReplyableMessage: CompactMessage;
  folder: ConversationFolder;
  status: ConversationStatus;
  assignment: ConversationAssignment;
  prioritized: boolean;
  snoozedUntil: string | undefined;
  replyForbidden: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum ConversationFolder {
  Inbox = 'inbox',
  Archive = 'archive',
  Trash = 'trash',
  Spam = 'spam',
}

export interface Message {
  id: string;
  conversationId: string;
  type: MessageType;
  content: string;
  snippet: string;
  sender: ConversationPerformer | undefined;
  emailParticipants: EmailParticipants | undefined;
  systemMetadata: SystemMetadata | undefined;
  tasksIds: number[];
  attachments: MessageAttachment[];
  drafts: Draft[];
  replyToMessage: CompactMessage | undefined;
  createdAt: string;
  updatedAt: string;
}

export interface PendingMessage extends Omit<Message, 'attachments'> {
  attachments: DraftAttachment[];
  traceId: string;
}

export interface EmailParticipants {
  from: EmailParticipant;
  to: EmailParticipant[];
  cc: EmailParticipant[];
  bcc: EmailParticipant[];
}

export interface SystemMetadata {
  type: SystemMessageType;
  performer: ConversationPerformer | undefined;
  assigneeUser: ConversationPerformer | undefined;
  members: ConversationPerformer[] | undefined;
}

export enum SystemMessageType {
  ConversationAssigned = 'conversation_assigned',
  ConversationUnassigned = 'conversation_unassigned',
  TeamMembersAdded = 'team_members_added',
  TeamMembersRemoved = 'team_members_removed',
}

export interface EmailParticipant {
  name: string | undefined;
  email: string;
  avatarUrl: string | undefined;
}

export interface MessageAttachment {
  id: string;
  name: string;
  size: number;
  mimeType: string;
}

export interface DraftAttachment {
  id: number;
  name: string;
  size: number;
  mimeType: string;
}

interface BaseDraft {
  id: number;
  externalAccountId: number;
  subject: string;
  type: DraftMessageType;
  content: string;
  signature: string;
  emailParticipants: Omit<EmailParticipants, 'from'> | undefined;
  tasksIds: number[];
  attachments: DraftAttachment[];
  createdAt: string;
  updatedAt: string;
}

export interface LinkedEmailDraft extends BaseDraft {
  conversationId: string;
  type: Extract<
    DraftMessageType,
    DraftMessageType.Note | DraftMessageType.Reply | DraftMessageType.ReplyAll | DraftMessageType.Forward
  >;
  quote: string;
  replyToMessage: CompactMessage;
}

export interface NewEmailDraft extends BaseDraft {
  type: DraftMessageType.NewEmail;
}

export type Draft = LinkedEmailDraft | NewEmailDraft;

export interface CompactMessage {
  id: string;
  conversationId: string;
  type: MessageType;
  snippet: string;
  sender: ConversationPerformer | undefined;
  emailParticipants: EmailParticipants | undefined;
  createdAt: string;
}

export interface ConversationAssignment {
  assignee: ConversationPerformer | undefined;
  availableAssignees: ConversationPerformer[];
}

export enum MessageType {
  Email = 'email',
  Text = 'text',
  System = 'system',
}

export enum DraftMessageType {
  NewEmail = 'NewEmail',
  Reply = 'Reply',
  ReplyAll = 'ReplyAll',
  Forward = 'Forward',
  Note = 'Note',
}

export interface ConversationUpdateEvent {
  workspaceId: number;
  conversationId: string;
  reason: ConversationUpdateReason;
}

export interface ConversationReadStateChangedEvent {
  conversationId: string;
  isInbox: boolean;
  isRead: boolean;
  workspaceId: number;
}

export type ConversationUpdateReason =
  | 'FullyUpdated'
  | 'LastUpdateDateChange'
  | 'StatusChanged'
  | 'RemoteStatusChanged'
  | 'SnoozeStatusChanged'
  | 'LabelsChanged'
  | 'AssigneeChanged'
  | 'MembersChanged'
  | 'PrioritizedChanged';

export interface NewConversationEvent {
  workspaceId: number;
  eventType: NewConversationEventType;
  conversationId: string;
  messageId: string;
  externalAccountId: number;
}

export enum NewConversationEventType {
  NewMessage = 'message.new',
}

export interface NewMessagePublishedEvent {
  workspaceId: number;
  conversationId: string;
  messageId: string;
  traceId: string;
}

export enum ConversationAssignmentErrorCode {
  AssigneeHasDraft = 'assignee_has_draft',
}
