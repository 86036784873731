import {
  ExternalAccount,
  ExternalAccountState,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';

export class LoadWorkspaces {
  static readonly type = '[Workspaces] Load Workspaces';
}

export class PatchExternalAccount {
  static readonly type = '[Workspaces] Patch External Account';

  constructor(
    public externalAccountId: number,
    public newValues: Partial<ExternalAccount>,
  ) {}
}

export class ConnectExternalAccount {
  static readonly type = '[Workspaces] Connect External Account';

  constructor(
    public state: ExternalAccountState,
    public code: string,
  ) {}
}

export class DisconnectExternalAccount {
  static readonly type = '[Workspaces] Disconnect External Account';

  constructor(
    public workspaceId: number,
    public externalAccountId: number,
  ) {}
}
