<div
  *ngIf="currentStep === steps.contactSelection"
  class="contact-selector_table"
>
  <h1>
    {{ (multiselect ? 'contactSelector.selectContacts' : 'contactSelector.selectAContact') | translate }}
  </h1>
  <app-table
    *ngIf="multiselect && tableConfig"
    [(data)]="contacts"
    [(selectedEntities)]="selectedContacts"
    [config]="tableConfig"
    [template]="rowTemplate"
  >
    <ng-template
      #rowTemplate
      let-user
    >
      <div class="company-contacts_name">
        <div class="company-contacts_contact-logo">
          <user-logo
            [logoUrl]="user.userLogoUrl"
            [user]="user.contact"
          ></user-logo>
        </div>
        <div>{{ user.contact.firstName }} {{ user.contact.lastName }}</div>
      </div>

      <div>
        <div>
          {{ user.contact.companyLocationName }}
        </div>
      </div>

      <div>
        <div>
          {{ user.contact.workEmail }}
        </div>
      </div>
    </ng-template>
  </app-table>

  <ng-container *ngIf="!multiselect && tableConfig">
    <app-table
      [(data)]="contacts"
      [config]="tableConfig"
      [template]="rowTemplate"
    >
      <ng-template
        #rowTemplate
        let-user
      >
        <div>
          <app-radio
            (click)="selectContact(user)"
            [checked]="user.contact.id === selectedContacts[0]?.contact.id"
          />
        </div>

        <div class="company-contacts_name">
          <div class="company-contacts_contact-logo">
            <user-logo
              [logoUrl]="user.userLogoUrl"
              [user]="user.contact"
            ></user-logo>
          </div>
          <div>{{ user.contact.firstName }} {{ user.contact.lastName }}</div>
        </div>

        <div>
          <div>
            {{ user.contact.companyLocationName }}
          </div>
        </div>

        <div>
          <div>
            {{ user.contact.workEmail }}
          </div>
        </div>
      </ng-template>
    </app-table>
  </ng-container>

  <div class="buttons-group">
    <button
      (click)="activeModal.dismiss()"
      class="btn btn-primary decline-btn"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>

    <button
      (click)="inviteNewUser()"
      *ngIf="!hideNewUserBtn && permissions.User_InviteNewUser && !this.companyId"
      class="btn btn-primary decline-btn"
    >
      {{ 'contactSelector.buttons.inviteUser' | translate }}
    </button>

    <button
      (click)="submitSelection()"
      [disabled]="!selectedContacts?.length"
      class="btn btn-primary submit-btn"
    >
      {{ (multiselect ? 'common.buttons.save' : 'contactSelector.buttons.selectContact') | translate }}
    </button>
  </div>
</div>

<div
  *ngIf="currentStep === steps.contactCreation"
  class="contact-selector_form"
>
  <form
    #ngForm="ngForm"
    (ngSubmit)="createContact()"
    [formGroup]="contactForm"
    autocomplete="off"
  >
    <h1>{{ 'inviteUserModal.inviteNewUser' | translate }}</h1>

    <div
      [ngClass]="{
        'has-error': ngForm.submitted && contactForm.get('firstName').invalid,
      }"
      class="form-group"
    >
      <label
        class="required"
        for="firstName"
      >
        {{ 'companyCreation.controls.firstName.label' | translate }}
      </label>

      <input
        formControlName="firstName"
        id="firstName"
        placeholder="{{ 'companyCreation.controls.firstName.placeholder' | translate }}"
        type="text"
      />

      <form-error-messages
        [control]="contactForm.get('firstName')"
        [showErrors]="ngForm.submitted"
      />
    </div>

    <div
      [ngClass]="{
        'has-error': ngForm.submitted && contactForm.get('lastName').invalid,
      }"
      class="form-group"
    >
      <label
        class="required"
        for="lastName"
      >
        {{ 'companyCreation.controls.lastName.label' | translate }}
      </label>

      <input
        formControlName="lastName"
        id="lastName"
        placeholder="{{ 'companyCreation.controls.lastName.placeholder' | translate }}"
        type="text"
      />

      <form-error-messages
        [control]="contactForm.get('lastName')"
        [showErrors]="ngForm.submitted"
      />
    </div>

    <div
      [ngClass]="{
        'has-error': ngForm.submitted && contactForm.get('workEmail').invalid,
      }"
      class="form-group"
    >
      <label
        class="required"
        for="workEmail"
      >
        {{ 'companyCreation.controls.workEmail.label' | translate }}
      </label>
      <input
        formControlName="workEmail"
        id="workEmail"
        placeholder="{{ 'companyCreation.controls.workEmail.placeholder' | translate }}"
        type="email"
      />
      <form-error-messages
        [control]="contactForm.get('workEmail')"
        [showErrors]="ngForm.submitted"
      >
      </form-error-messages>
    </div>

    <div class="buttons-group">
      <button
        (click)="backToList()"
        [disabled]="isLoading"
        class="btn btn-primary decline-btn"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
      <button
        [appBtnLoading]="isLoading"
        [disabled]="ngForm.submitted && contactForm.invalid"
        class="btn btn-primary submit-btn"
        type="submit"
      >
        {{ 'inviteUserModal.buttons.' + (isAssigningTask ? 'inviteAndAssignTask' : 'sendInvite') | translate }}
      </button>
    </div>
  </form>
</div>
